import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    message: null,
    discriminator: null,
    name: null,
    id: null
  },
  mutations: {
    addToken(state, token) {
      state.token = token
    },
    setMessage(state, credentials) {
      state.message = credentials.message
    },
    userPreference(state, credentials) {
      state.id = credentials.id
      state.discriminator = credentials.discriminator
      state.name = credentials.name
    },
    logout(state) {
      localStorage.removeItem('access');
      //localStorage.removeItem('responseUser');
      state.token = null;
      state.discriminator = null;
      state.name = null;
      state.id = null;
    }
  },
  actions: {
    token(context, credentials) {
      context.commit("addToken", credentials.token);
      
    },
    preference(context, user) {
      if(user !== null){
        context.commit("userPreference",  {
          id: user.id,
          name: user.name,
          discriminator: user.discriminator
        });
      }else{
        context.commit("userPreference",  {
          id: null,
          name: null,
          discriminator: null
        });
      }
    },
    message(context, credentials) {
      context.commit("setMessage", credentials)
    },
    logout(context) {
      context.commit("logout")
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    token: state => state.token,
    id: state => state.id,
    discriminator: state => state.discriminator,
    name: state => state.name,
    message: state => state.message
  }
})
