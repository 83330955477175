import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import auth from '@/services/api/auth'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '*',
      name: '404',
      component: () => import('./views/404.vue'),
      meta: {
        layout: 'default'
      }
    },
    {
      path: '/',
      name: 'Auth',
      component: () => import('./views/Auth.vue'),
      meta: {
        layout: 'default'
      }
    },
    {
      path: '/activate/:token',
      name: 'Activate',
      component: () => import('./views/Activate.vue'),
      meta: {
        layout: 'default'
      }
    },
    {
      path: '/recovery',
      name: 'Recovery',
      component: () => import('./views/Recovery.vue'),
      meta: {
        layout: 'default'
      }
    },
    {
      path: '/notes',
      name: 'Notes',
      component: () => import('./views/admin/Note.vue'),
      meta: {
        layout: 'admin'
      }
    },
    {
      path: '/notes/print',
      name: 'NotesPrint',
      component: () => import('./views/admin/PrintNotes.vue'),
      meta: {
        layout: 'empty'
      }
    },
    {
      path: '/tasks',
      name: 'Tasks',
      component: () => import('./views/admin/Todo.vue'),
      meta: {
        layout: 'admin'
      }
    },
    {
      path: '/task/:id',
      name: 'Task',
      component: () => import('./views/admin/TodoView.vue'),
      meta: {
        layout: 'admin'
      }
    },
    {
      path: '/tasks/print',
      name: 'TasksPrint',
      component: () => import('./views/admin/PrintTask.vue'),
      meta: {
        layout: 'empty'
      }
    },
    {
      path: '/reset/:token',
      name: 'Reset',
      component: () => import('./views/Reset.vue'),
      meta: {
        layout: 'default'
      }
    },
    {
      path: '/admin',
      name: 'Dashboard',
      component: () => import('./views/admin/Main.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Dashboard' }
        ]
      },
      beforeEnter: (to, from, next) => {    //Ejemplo middleware
        if (!store.getters.isLoggedIn) {
          return next({
            name: 'Auth'
          })
        }
        next()
      }
    },
    {
      path: '/admin/bank',
      name: 'Bank',
      component: () => import('./views/admin/Bank.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Bank' }
        ]
      }
    },
    {
      path: '/admin/bank-account',
      name: 'Bank Account',
      component: () => import('./views/admin/BankAccount.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Bank account' }
        ]
      }
    },
    {
      path: '/admin/credential-input',
      name: 'Credential Input',
      component: () => import('./views/admin/CredentialInput.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Credential Input' }
        ]
      }
    },
    {
      path: '/admin/clasification',
      name: 'Business Classification',
      component: () => import('./views/admin/Clasification.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Business Classification' }
        ]
      }
    },
    {
      path: '/admin/type',
      name: 'Corporation Types',
      component: () => import('./views/admin/Type.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Corporation Types' }
        ]
      }
    },
    {
      path: '/admin/title',
      name: 'Titles',
      component: () => import('./views/admin/Title.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Titles' }
        ]
      }
    },
    {
      path: '/admin/payment-option',
      name: 'Payment Option',
      component: () => import('./views/admin/PaymentOption.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Payment Option' }
        ]
      }
    },
    {
      path: '/admin/payment-plan',
      name: 'Payment Plan',
      component: () => import('./views/admin/PaymentPlan.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Payment Plan' }
        ]
      }
    },
    {
      path: '/admin/forms',
      name: 'Forms',
      component: () => import('./views/admin/Form.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Forms' }
        ]
      }
    },
    {
      path: '/admin/office',
      name: 'Office',
      component: () => import('./views/admin/Office.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Office' }
        ]
      }
    },
    {
      path: '/admin/corporate',
      name: 'Clients',
      component: () => import('./views/admin/Corporate.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Clients' }
        ]
      }
    },
    {
      path: '/admin/notification',
      name: 'Notification',
      component: () => import('./views/admin/Notification.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Notification' },
        ]
      }
    },
    {
      path: '/admin/notification/queued',
      name: 'Queued Notification',
      component: () => import('./views/admin/QueuedNotification.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Notification' },
          { name: 'Queued' },
        ]
      }
    },
    {
      path: '/admin/notification/configuration',
      name: 'New Notification Configuration',
      component: () => import('./views/admin/NotificationConfig.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Notification', link: '/notification' },
          { name: 'Configuration' }
        ]
      }
    },
    {
      path: '/admin/notification/new',
      name: 'New Notification',
      component: () => import('./views/admin/NewNotification.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Notification', link: '/notification' },
          { name: 'New' }
        ]
      }
    },
    {
      path: '/admin/notification/new/:office',
      name: 'New Notification Office',
      component: () => import('./views/admin/NewNotificationOffice.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Notification', link: '/notification' },
          { name: 'New' }
        ]
      }
    },
    {
      path: '/admin/plan',
      name: 'Plan',
      component: () => import('./views/admin/Plan.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Plan' }
        ]
      }
    },
    {
      path: '/admin/scheme',
      name: 'Scheme',
      component: () => import('./views/admin/Scheme.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Scheme' }
        ]
      }
    },
    {
      path: '/admin/user',
      name: 'User',
      component: () => import('./views/admin/User.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'User' }
        ]
      }
    },
    {
      path: '/admin/representative',
      name: 'Representative',
      component: () => import('./views/admin/Representative.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Representative' }
        ]
      }
    },
    {
      path: '/admin/profile',
      name: 'Profile',
      component: () => import('./views/admin/Profile.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Profile' }
        ]
      }
    },
    {
      path: '/admin/license-type',
      name: 'License type',
      component: () => import('./views/admin/LicenseType.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'License type' }
        ]
      }
    },
    {
      path: '/admin/license',
      name: 'License',
      component: () => import('./views/admin/License.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'License' }
        ]
      }
    },
    {
      path: '/admin/client/set/:id',
      name: 'Client set',
      component: () => import('./views/admin/Set.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Corporate', link: '/admin/corporate' },
          { name: 'Set' }
        ]
      }
    },
    {
      path: '/admin/client/print/:id',
      name: 'Client print',
      component: () => import('./views/admin/Print.vue'),
      meta: {
        layout: 'empty'
      }
    },
    {
      path: '/admin/Statement',
      name: 'Statement',
      component: () => import('./views/admin/Statement.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Statement' },
        ]
      }
    },
    {
      path: '/admin/sale-tax',
      name: 'Sales Tax',
      component: () => import('./views/admin/SaleTax.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'States Sales Tax' },
        ]
      }
    },
    {
      path: '/admin/sale-tax/states',
      name: 'States Sales Tax',
      component: () => import('./views/admin/StateSalesTax.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Sale tax' },
        ]
      }
    },
    {
      path: '/admin/sale-tax/clients',
      name: 'Sales Tax Clients',
      component: () => import('./views/admin/ClientsSaleTax.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Sales Tax', link: '/admin/sale-tax' },
          { name: 'Sales Tax Clients' }
        ]
      }
    },
    {
      path: '/sale-tax/print',
      name: 'SaleTaxPrint',
      component: () => import('./views/admin/PrintSaleTax.vue'),
      meta: {
        layout: 'empty'
      }
    },
    {
      path: '/admin/coverage',
      name: 'Coverage',
      component: () => import('./views/admin/Coverage.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Coverage' }
        ]
      }
    },
    {
      path: '/admin/wholesaler',
      name: 'Wholesaler',
      component: () => import('./views/admin/Wholesaler.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Wholesaler' }
        ]
      }
    },
    {
      path: '/admin/carrier',
      name: 'Carrier',
      component: () => import('./views/admin/Carrier.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Carrier' }
        ]
      }
    },
    {
      path: '/admin/finance-companies',
      name: 'Finance Companies',
      component: () => import('./views/admin/FinanceCompany.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Finance Companies' }
        ]
      }
    },
    {
      path: '/admin/insurance',
      name: 'Insurance',
      component: () => import('./views/admin/Insurance.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Insurance Policies' },
        ]
      }
    },
    {
      path: '/admin/insurance/print',
      name: 'insurancePrint',
      component: () => import('./views/admin/PrintInsurance.vue'),
      meta: {
        layout: 'empty'
      }
    },
    {
      path: '/admin/installments',
      name: 'installments',
      component: () => import('./views/admin/Installments.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Insurance Installments' },
        ]
      }
    },
    {
      path: '/admin/payroll-tax',
      name: 'Payroll tax',
      component: () => import('./views/admin/PayrollTax.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Payroll tax' },
        ]
      }
    },
    {
      path: '/admin/corp-tax',
      name: 'Corp tax',
      component: () => import('./views/admin/CorpTax.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Corp tax' },
        ]
      }
    },
    {
      path: '/admin/form-nec',
      name: 'Form Nec',
      component: () => import('./views/admin/FormNec.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/admin' },
          { name: 'Form Nec' }
        ]
      }
    },
    {
      path: '/admin/document-manager',
      name: 'Document Manager',
      component: () => import('./views/admin/DocumentManager.vue'),
      meta: {
        layout: 'admin',
        withoutinfo: true
      }
    },
    {
      path: '/admin/document-manager/panel',
      name: 'Control Panel',
      component: () => import('./views/admin/PanelDocumentManager.vue'),
      meta: {
        layout: 'admin',
        breadcrumb: [
          { name: 'Main', link: '/panel' },
          { name: 'Control Panel' }
        ]
      }
    },
    {
      path: '/guest/:token',
      name: 'Guest',
      component: () => import('./views/admin/DocumentManager.vue'),
      meta: {
        layout: 'guest'
      }
    },
  ]
})

router.beforeEach((to, _, next) => {
  if(to.name !== 'Guest') {
    if(localStorage.getItem('access')!==null){
        auth.me(localStorage.getItem('access')).then(response => {
          store.dispatch("preference", response.user);
          if(response.user.discriminator == 'shareholder') {
            if (to.name !== "Document Manager" && to.name !== "Profile") {
              return next({ name: "Document Manager" });
            }
            next();
          }else if(to.name === "Auth" && response.user.discriminator !== 'guest') {
            return next({ name: "Dashboard" });
          }
          next();
        }).catch(() => {
          store.dispatch("logout");
          return next({ name: "Auth" });
        });
    } else {
      if (to.name !== "Auth") {
        return next({ name: "Auth" });
      }
      next();
    }
  } else {
    next();
  }
});

export default router;