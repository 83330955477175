import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'en',
  messages: {
    en: {
      login: {
        email: "E-mail",
        password: "Password"
      },
      recovery: {
        email: "E-mail",
        reset: "RESET"
      },
      register: {
        name: "Name",
        phone: "Phone",
        password: "Password",
        confirm: "Confirm Password",
        register: "REGISTER"
      },
      office: {
        name: "Name",
        dba: "DBA",
        phone: "Phone",
        fax: "Fax",
        ein: "EIN",
        address: "Address",
        city: "City",
        zip: "Zip"
      },
      user: {
        name: "Name",
        email: "Email",
        phone: "Phone",
        address: "Address",
        city: "City",
        zip: "Zip",
        office: "Office"
      },
      corporate: {
        name: "Name",
        address: "Anddress",
        website: "Web Site",
        phone: "Phone",
        fax: "Fax",
        city: "City",
        zip: "Zip",
        clasification: "Business Classification",
        certification: "Cert. of Authority",
        federal: "Federal Id",
        started: "Business Started",
        incorporat: "Date Of Incorporate",
        fiscalyear: "Fiscal Year"
      },
      shareholder: {
        firstname: "Name",
        lastname: "Last Name",
        title: "Title",
        socialsecurity: "Soc. Sec",
        address: "Address",
        city: "City",
        zip: "Zip",
        birthday: "Birth Day",
        share: "Shares",
        email: "Email",
        phone: "Cellphone"
      },
      landlord: {
        name: "Name",
        address: "Address",
        city: "City",
        zip: "Zip",
        phone: "Phone",
        mobile: "Mobile",
        email: "Email",
        keep: "To keep in mind"
      },
      formerowner: {
        name: "Name",
        address: "Address",
        city: "City",
        zip: "Zip",
        phone: "Phone",
        mobile: "Mobile",
        email: "Email",
        keep: "To keep in mind"
      },
      bank: {
        bank: "Bank",
        link: "Link",
        type: "Type",
        user: "User",
        password: "Password",
        routing: "Routing",
        account: "Account"
      },
      credential: {
        description: "Description",
        link: "Link",
        user: "User",
        password: "Password",
        pin: "Pin",
        other: "Other"
      },
      license: {
        company: "Company",
        licensetype: "License Type",
        serial: "Serial",
        pin: "Pin",
        priordaysremainder: "Prior Days Remainder",
        status: "License Status",
        web: "Web",
        forwarder: "Forwarder",
        user: "User",
        password: "Password",
        observation: "Observation",
        issuedate: "Issue Date",
        duedate: "Due Date"
      },
      licensetype: {
        name: "Name"
      },
      clasification: {
        code: "Code",
        name: "Name"
      },
      bankaccount: {
        name: "Name"
      },
      scheme: {
        name: "Name"
      },
      credentialinput: {
        name: "Name"
      },
      fee: {
        plan: "Plan",
        scheme: "Payment Frequency",
        start: "Start On",
        fee: "Fee"
      }
    }
  }
})
