import store from '@/store'

export default {
    inserted(el, binding, vnode) {
        const { value } = binding
        const roles = store.getters.discriminator

        if (value && value instanceof Array && value.length > 0) {
            const permissionRoles = value
            var hasPermission = permissionRoles.some((el) => {
                return el === roles;
            });
            if (!hasPermission) {
                el.parentNode && el.parentNode.removeChild(el)
            }
        } else {
            throw new Error(`need roles! Like v-permission="['xxx','xxx']"`)
        }

    }
}
