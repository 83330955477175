import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import preference from "@/services/helpers/preference";
import "@/mixins/index";


Vue.config.productionTip = false

store.dispatch("token", {
  token: localStorage.getItem('access')
});



// moment
import VueMoment from 'vue-moment';
Vue.use(VueMoment)

// axios
import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;

// vue-resource

import VueResource from 'vue-resource'
Vue.use(VueResource);

// element ui
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'

Vue.use(ElementUI, { locale })

// i18n
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

// interceptor
import interceptorsSetup from './services/helpers/interceptor'
interceptorsSetup();


// lang
import { i18n } from './lang/i18n'

// ngprogress 
import 'nprogress/nprogress.css'

// perfect scrollbar 
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

Vue.use(PerfectScrollbar)

//SweetAlert2
import VueSweetalert2 from 'vue-sweetalert2';
 
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
 
Vue.use(VueSweetalert2);

//select2

import vSelect from 'vue-select';

Vue.component('v-select', vSelect)

import 'vue-select/dist/vue-select.css';

// permission
import './permission' // permission control

// lodash
import VueLodash from 'vue-lodash'
const options = { name: 'lodash' }
Vue.use(VueLodash, options)

import VueMask from 'v-mask'
Vue.use(VueMask)

import vuetify from 'vuetify'
Vue.use(vuetify)

import VModal from 'vue-js-modal'
Vue.use(VModal, { dialog: true })

import {formater} from './services/helpers/functions'

Vue.prototype.$formater = formater

preference().then(() =>{
    new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  }
)

