import axios from 'axios'

export default {
    async login(data) {
        try {
            const response = await axios.post('/auth/login', {
                email: data.email,
                password: data.password,
                type: data.type,
                securitySocial: data.securitySocial
            });
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async me(token){
        try {
            const response = await axios.get('auth/me');
            if(response.status === 204) {
                return Promise.reject();
            }
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async check(data) {
        try {
            const response = await axios.get(`/check/${data}`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async activate(data) {
        try {
            const response = await axios.put(`/activate/${data.token}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async recovery(data) {
        try {
            const response = await axios.post('/recovery', {
                email: data.email
            });
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async reset(data) {
        try {
            const response = await axios.put(`/reset/${data.token}`, {
                password: data.password
            });
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async refresh() {
        try {
            const response = await axios.post('/auth/refresh');
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async logout() {
        try {
            const response = await axios.post('/auth/logout');
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    }
}
