<template>
  <vue-extend-layouts />
</template>


<script>
import VueExtendLayouts from "vue-extend-layout";
export default {
  name: "App",
  components: {
    VueExtendLayouts
  }
};
</script>

<style lang="scss">
@import "assets/style/element-variables";
@import "assets/style/theme";
@import "assets/style/all.css";
// import custom modules

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/badge";

body,
html {
  height: 100%;
}
a {
  cursor: pointer;
}
</style>