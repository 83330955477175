import axios from 'axios';
import store from '../../store';
import auth from "@/services/api/auth";
import router from "@/router";

import NProgress from 'nprogress'
NProgress.configure({ showSpinner: false });

export default function setup() {
    axios.interceptors.request.use(function (config) {
        NProgress.start();
        const token = store.getters.token;
        if (token) {
            config.headers.Authorization = 'Bearer ' + token;
        }else{
            config.headers.Authorization = null;
            localStorage.removeItem('access');
        }
        return config;
    }, function (err) {
        return Promise.reject(err);
    });

    axios.interceptors.response.use(function (response) {
        NProgress.done();
        return response;
    }, function (err) {
        store.dispatch('message', {
            message: err.response.data
        })
        return Promise.reject(err);
    });

    axios.interceptors.response.use(null, (error) => {
        if (error.response && error.response.status === 401) {
            if(localStorage.getItem('access')!==null){
                return auth.refresh()
                    .then(response => {
                        localStorage.removeItem('access');
                        if(response.token !== null){
                            localStorage.setItem('access', response.token);
                            store.dispatch('token', {
                                token: response.token
                            })
                            return axios.request(error.response.config);
                        }else{
                            store.dispatch("logout");
                            location.href = "/";
                        }
                        
                    }).catch(err =>{
                        store.dispatch("logout");
                        location.href = "/";
                    });
            }
        }else if(error.response && error.response.status === 429) {
            store.dispatch("logout");
            location.href = "/";
        }
    }, function (err) {
        return Promise.reject(err);
    });
}