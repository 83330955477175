import Vue from 'vue'

Vue.mixin({
    methods:{
        openBackgroundOpacity() {
            var background = document.getElementById("background-opacity");
            setTimeout(function(event){
                background.style.display = "block";
            },1)
        },
        closeBackgroundOpacity() {
            var background = document.getElementById("background-opacity");
            background.style.display = "none";
        }
    }
})